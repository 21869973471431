import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { DataGrid, GridColumns } from '@material-ui/data-grid';
import { IoMdRemoveCircle } from 'react-icons/io';
import { IoMdAddCircle } from 'react-icons/io';
import { ButtonItens, ButtonRow } from '../../style';
import { useIdenficacaoContabil } from '../../IdenficacaoContabilContext';
import { ModalNovoRetencao } from '../../Components/ModalAbaRetencao';
import { InputSelect } from '~/components/NovosInputs';
import { SelectProps } from '../../types';
import { deleteItens } from '~/utils/masterDetail';

export const TabRetencao: React.FC = () => {
  const {
    masterDetail,
    setMasterDetail,

    setValue,

    handleOpenModalRetencao,
    register,
    control,
    errors,
    rowsRetencao,
    setRowsRetencao,
  } = useIdenficacaoContabil();

  const retencaoColumns: GridColumns = [
    {
      field: 'id',
      headerName: 'Id',
      hide: true,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'fornecedor_beneficiario',
      headerName: 'Fornecedor Beneficiario',
      flex: 2,
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return <>{row.fornecedor_beneficiario.label}</>;
      },
    },
    {
      field: 'retencao',
      headerName: 'Retenção',
      flex: 2,
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return <>{row.retencao.label}</>;
      },
    },
    {
      field: 'acao',
      headerName: 'Ação',
      sortable: false,
      flex: 1,
      disableColumnMenu: true,
      headerClassName: 'hideRightSeparator',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: { row: any }) => {
        const onDelete = () => {
          const { row } = params;
          handleRemoveRetencao(row.uuid);
        };

        return (
          <>
            <ButtonRow type="button" onClick={onDelete}>
              <IoMdRemoveCircle size={20} style={{ color: '#e63c3c' }} />
            </ButtonRow>
          </>
        );
      },
    },
  ];

  const handleRemoveRetencao = async (uuid: string) => {
    const retencaoDetail: any[] = await deleteItens(
      'retencao',
      uuid,
      masterDetail,
      setMasterDetail,
    );
    setRowsRetencao(retencaoDetail);
  };

  return (
    <>
      <Row>
        <Col
          md={12}
          sm={12}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <ButtonItens onClick={handleOpenModalRetencao} type="button">
            <IoMdAddCircle color="#2D7BE8" />
            <span>Incluir Novo</span>
          </ButtonItens>
        </Col>
      </Row>
      <Row>
        <Col md={12} sm={12} style={{ marginTop: '10px', height: '400px' }}>
          <DataGrid
            rows={rowsRetencao}
            className="myDataGrid"
            columns={retencaoColumns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            localeText={{
              noRowsLabel: 'Nenhum registro encontrado',
              columnMenuLabel: 'Menu',
              columnMenuFilter: 'Filtrar',
              columnMenuHideColumn: 'Esconder',
              columnMenuUnsort: 'Não ordenar',
              columnMenuSortAsc: 'Ordernar ASC',
              columnMenuSortDesc: 'Ordernar DESC',
              columnMenuShowColumns: 'Mostrar columnas',
            }}
          />
        </Col>

        <ModalNovoRetencao />
      </Row>
      <Row>
        <Col
          md={12}
          sm={12}
          style={{
            display: 'flex',
            alignItems: 'flex-end',
          }}
        >
          <span
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              marginBottom: '10px',
              marginRight: '10px',
            }}
          >
            Tipo operação:
          </span>

          <InputSelect
            name="tipo_operacao"
            placeholder="Selecione uma opção"
            register={register}
            options={[
              { value: 0, label: 'Entrada/Dev. Entrada' },
              { value: 1, label: 'Saída/Dev. Saída' },
            ]}
            isError={!!errors.tipo_operacao}
            control={control}
            changeSelected={(selected: SelectProps) => {
              setValue('tipo_operacao', selected);
            }}
          />
        </Col>
      </Row>
    </>
  );
};
