import * as yup from 'yup';

export const schema = yup
  .object({
    descricao: yup.string().required(),
    cod_bc_cred: yup.object().when('flg_ignora_pis_cofins', {
      is: (value: any) => {
        if (!value) return true;
        return false;
      },
      then: yup.object().shape({
        label: yup.string().required(),
        value: yup.number().required(),
      }),
      otherwise: yup.object().notRequired().nullable(true),
    }),
    cod_tipo_cred: yup.object().when('flg_ignora_pis_cofins', {
      is: (value: any) => {
        if (!value) return true;
        return false;
      },
      then: yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
      }),

      otherwise: yup.object().notRequired().nullable(true),
    }),
  })
  .required();
export const impostosSchema = yup
  .object({
    campo: yup.object().shape({
      label: yup.string().required(),
      value: yup.string().required(),
    }),
    historico_padrao: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    conta_debito: yup.string().required(),
    conta_credito: yup.string().required(),
  })
  .required();

export const retencaoSchema = yup
  .object({
    fornecedor_beneficiario: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    retencao: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
  })
  .required();
