import React, { useState, useCallback, useContext } from 'react';

import { Container } from './style';
import FormDefault from '~/components/FormDefault';
import Search from '~/components/Search';

import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { InputText, InputSelect } from '~/components/NovosInputs';
import {
  dadosImposto,
  masterDetailDefault,
  SelectProps,
  TabExtratoProps,
} from './types';
import { TabImposto } from './Tabs/TabImposto';
import { TabRetencao } from './Tabs/TabRetencao';
import { TabSpedPisConfins } from './Tabs/TabSpedPisConfins';
import { useIdenficacaoContabil } from './IdenficacaoContabilContext';
import ContaContabil from '~/components/ContaContabil';
import { toast } from 'react-toastify';
import { loadingContext } from '~/context/loading';
import DefaultLoader from '~/components/DefaultLoader';
import { identificacaoContabilApi } from './services';
import { nanoid } from 'nanoid';
import { addUpdate } from '~/utils/masterDetail';

const defaultForm = {
  descricao: '',
  conta_debito: undefined,
  conta_credito: undefined,
  mov_fisica_item: { value: undefined, label: undefined },
  cod_tipo_cred: { value: undefined, label: undefined },
  cod_bc_cred: { value: undefined, label: undefined },
  flg_ignora_pis_cofins: false,
  flg_ignora_icms_nf_cliente: false,
  tipo_operacao: { value: undefined, label: undefined },
};

const optionsMovFisica = [
  { value: 0, label: 'SIM' },
  { value: 1, label: 'NÃO' },
];
const optionsTipoOperacao = [
  { value: 0, label: 'Entrada/Dev. Entrada' },
  { value: 1, label: 'Saída/Dev. Saída' },
];

export const IdenficacaoContabilContent: React.FC = () => {
  const {
    handleSubmit,
    setError,
    reset,
    showSearch,
    setShowSearch,
    setValue,
    register,
    control,
    setTab,
    tab,
    errors,
    handleResetImpostos,
    setIsUpdate,
    isUpdate,
    getValues,
    fetchSpedCodigosTipoCredito,
    fetchSpedCodigosBcCredito,

    masterDetail,
    setRowsRetencao,
    setRowsImpostos,
    setMasterDetail,
  } = useIdenficacaoContabil();

  const { setLoading, loading } = useContext(loadingContext);

  const [contaCredito, setContaCredito] = useState<string>('');
  const [contaDebito, setContaDebito] = useState<string>('');

  const onRowClick = useCallback(
    async (param: any) => {
      const {
        cod_id_ctb,
        des_id_ctb,
        flg_ignora_pis_cofins,
        flg_ignora_icms_nf_cli,
        tipo_mov_fisica_item,
        cod_tab_sped_4_3_6,
        cod_tab_sped_4_3_7,
        cod_conta_deb,
        cod_conta_cred,
        tipo_operacao,
      } = param.row;

      setValue('cod_id_ctb', cod_id_ctb);
      setTab('Imposto');

      try {
        await fetchSpedCodigosTipoCredito('4.3.6');
        await fetchSpedCodigosBcCredito('4.3.7');
        const getImpostos = await identificacaoContabilApi.getImpostos(
          cod_id_ctb,
        );
        const getRetencoes = await identificacaoContabilApi.getRetencoes(
          cod_id_ctb,
        );

        if (getImpostos.data.length > 0) {
          const { data } = getImpostos;

          const impostos = data.map((ent: any) => {
            const uuid = nanoid();
            const campoSelect = dadosImposto.filter(
              (imposto) => imposto.value === ent.campo,
            );
            return {
              uuid,
              id: uuid,
              cod_seq_id_ctb_imposto: ent.cod_seq_id_ctb_imposto,
              cod_id_ctb: ent.cod_id_ctb,
              campo:
                campoSelect.length > 0
                  ? campoSelect[0]
                  : { value: undefined, label: '' },
              conta_credito: ent.cod_conta_cred,
              conta_debito: ent.cod_conta_deb,
              historico_padrao: {
                value: ent.cod_historico,
                label: ent.des_historico_padrao,
              },
            };
          });

          await addUpdate('impostos', impostos, masterDetail, setMasterDetail);
          setRowsImpostos(impostos);
        } else {
          setRowsImpostos([]);
        }
        if (getRetencoes.data.length > 0) {
          const { data } = getRetencoes;

          const retencoes = data.map((ent: any) => {
            const uuid = nanoid();

            return {
              uuid,
              id: uuid,
              cod_seq_id_forn_ret: ent.cod_seq_id_forn_ret,
              cod_id_ctb,
              fornecedor_beneficiario: {
                value: ent.cod_fornecedor,
                label: ent.des_fornecedor,
              },
              retencao: { label: ent.des_retencao, value: ent.cod_retencao },
            };
          });

          await addUpdate('retencao', retencoes, masterDetail, setMasterDetail);
          setRowsRetencao(retencoes);
        } else {
          setRowsRetencao([]);
        }

        setValue('cod_id_ctb', cod_id_ctb);

        setValue(
          'tipo_operacao',
          tipo_operacao !== null
            ? optionsTipoOperacao.find(
                (operacao) => operacao.value === tipo_operacao,
              )
            : null,
        );

        setValue('descricao', des_id_ctb);
        setValue('flg_ignora_pis_cofins', flg_ignora_pis_cofins);
        setValue('flg_ignora_icms_nf_cliente', flg_ignora_icms_nf_cli);

        if (cod_conta_deb) {
          try {
            const { success, data } =
              await identificacaoContabilApi.getContaContabil(cod_conta_deb);
            if (success) {
              setContaDebito(data[0].conta_reduzida);
              setValue('conta_debito', cod_conta_deb);
            }
          } catch (error) {
            setContaDebito('');
            setValue('conta_debito', undefined);
          }
        }
        if (cod_conta_cred) {
          try {
            const { success, data } =
              await identificacaoContabilApi.getContaContabil(cod_conta_cred);

            if (success) {
              setValue('conta_credito', cod_conta_cred);
              setContaCredito(data[0].conta_reduzida);
            }
          } catch (error) {
            setValue('conta_credito', undefined);
            setContaCredito('');
          }
        }
        const opTipoCred = getValues('optionTipoCred');
        const opBcCred = getValues('optionBcCred');

        setValue(
          'cod_tipo_cred',
          cod_tab_sped_4_3_6
            ? opTipoCred.find(
                (option: any) =>
                  Number(option.value) === Number(cod_tab_sped_4_3_6),
              )
            : null,
        );
        setValue(
          'cod_bc_cred',
          cod_tab_sped_4_3_7
            ? opBcCred.find(
                (option: any) =>
                  Number(option.value) === Number(cod_tab_sped_4_3_7),
              )
            : null,
        );

        setValue(
          'mov_fisica_item',
          tipo_mov_fisica_item !== -1
            ? optionsMovFisica.find((mov) => mov.value === tipo_mov_fisica_item)
            : null,
        );
      } catch (error) {
        console.log(error);
      }

      setIsUpdate(true);
      setLoading(false);
      setShowSearch(false);
    },
    [
      fetchSpedCodigosBcCredito,
      fetchSpedCodigosTipoCredito,
      getValues,
      masterDetail,
      setIsUpdate,
      setLoading,
      setMasterDetail,
      setRowsImpostos,
      setRowsRetencao,
      setShowSearch,
      setTab,
      setValue,
    ],
  );

  const onNewData = () => {
    resetFormData();
    setShowSearch(false);
  };

  const onSave = handleSubmit(async (data) => {
    if (data.conta_credito && data.conta_debito) {
      if (data.conta_credito === data.conta_debito) {
        setError('conta_credito', { type: 'required' });
        setError('conta_debito', { type: 'required' });
        return toast.warning(
          'Conta credito e conta debito devem ser diferentes.',
        );
      }
    }

    const impostosMasterDetail = masterDetail.find(
      (master) => master.obj_name === 'impostos',
    );
    const retencaoMasterDetail = masterDetail.find(
      (master) => master.obj_name === 'retencao',
    );

    const prepareData = {
      des_id_ctb: data.descricao,
      cod_conta_cred: data.conta_credito,
      cod_conta_deb: data.conta_debito,
      tipo_operacao: data.tipo_operacao ? data.tipo_operacao.value : null,
      tipo_mov_fisica_item: data.mov_fisica_item
        ? data.mov_fisica_item.value
        : -1,
      flg_ignora_pis_cofins: data.flg_ignora_pis_cofins,
      flg_ignora_icms_nf_cli: data.flg_ignora_icms_nf_cliente,
      cod_tab_sped_4_3_6: data.cod_tipo_cred.value
        ? data.cod_tipo_cred.value
        : null,
      cod_tab_sped_4_3_7: data.cod_bc_cred.value
        ? data.cod_bc_cred.value
        : null,
      impostos: impostosMasterDetail ? impostosMasterDetail.itens : null,
      retencao: retencaoMasterDetail ? retencaoMasterDetail.itens : null,
    };
    try {
      if (isUpdate) {
        const { success } =
          await identificacaoContabilApi.putIdentificacaoContabil(
            prepareData,
            data.cod_id_ctb,
          );

        if (success) {
          toast.success('Identificação Contábil atualizada com sucesso.');

          resetFormData();
          setShowSearch(true);
        }

        return;
      }

      const { success } =
        await identificacaoContabilApi.postIdentificacaoContabil(prepareData);

      if (success) {
        toast.success('Identificação Contábil cadastrada com sucesso.');
        resetFormData();
      }
    } catch (error) {
      setLoading(false);
    }
  });

  const resetFormData = useCallback(() => {
    setIsUpdate(false);
    reset(defaultForm);
    setContaCredito('');
    setContaDebito('');
    handleResetImpostos();
    setRowsRetencao([]);
    setRowsImpostos([]);

    setMasterDetail(JSON.parse(JSON.stringify(masterDetailDefault)));

    setTab('Imposto');
  }, [
    handleResetImpostos,
    reset,
    setIsUpdate,
    setMasterDetail,
    setRowsImpostos,
    setRowsRetencao,
    setTab,
  ]);

  const onDelete = async () => {
    const { cod_id_ctb } = getValues();
    try {
      const { success, message } =
        await identificacaoContabilApi.deleteIdentificacaoContabil(cod_id_ctb);

      if (success) {
        resetFormData();
        setShowSearch(true);
        return toast.success(message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) {
    return <DefaultLoader />;
  }

  return (
    <Container>
      {showSearch && (
        <Search codTela={279} newData={onNewData} onRowClick={onRowClick} />
      )}
      {!showSearch && (
        <FormDefault
          codTela={279}
          title="Identificação Contábil"
          codigoRegistro={[
            { value: getValues('cod_id_ctb'), des_campo: 'Código' },
          ]}
          onSave={onSave}
          onCancel={() => {
            resetFormData();
            setShowSearch(true);
          }}
          isUpdate={isUpdate}
          onClearFields={resetFormData}
          onNew={onNewData}
          onDelete={onDelete}
          onReturnSearch={() => {
            resetFormData();
            setShowSearch(true);
          }}
        >
          <Row>
            <Col md={12} sm={12}>
              <InputText
                label="Descrição"
                placeholder="Informe a descrição"
                register={register}
                name="descricao"
                toLowerCase={false}
                isError={!!errors.descricao}
                maxLength={50}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6} sm={12}>
              <ContaContabil
                label="Conta Debito"
                value={contaDebito}
                maxLength={3}
                min={0}
                max={1000}
                lengthBuscaConta={0}
                isRequired
                setInvalid={!!errors.conta_debito}
                iniInicializado={!!errors.conta_debito}
                isDisabled={false}
                onChange={(newValue: any, isInvalid: any, cod_conta: any) => {
                  setContaDebito(newValue);
                  setValue('conta_debito', cod_conta);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6} sm={12}>
              <ContaContabil
                label="Conta Credito"
                value={contaCredito}
                maxLength={3}
                min={0}
                lengthBuscaConta={0}
                max={1000}
                isRequired
                setInvalid={!!errors.conta_credito}
                iniInicializado={!!errors.conta_credito}
                isDisabled={false}
                onChange={(newValue: any, isInvalid: any, cod_conta: any) => {
                  setContaCredito(newValue);
                  setValue('conta_credito', cod_conta);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col
              md={12}
              sm={12}
              style={{
                display: 'flex',
                alignItems: 'flex-end',
              }}
            >
              <span
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  marginBottom: '10px',
                  marginRight: '10px',
                }}
              >
                Movimentação Física do item(SPEED):
              </span>

              <InputSelect
                name="mov_fisica_item"
                placeholder="Selecione uma opção"
                register={register}
                options={optionsMovFisica}
                isError={!!errors.mov_fisica_item}
                control={control}
                changeSelected={(selected: SelectProps) => {
                  setValue('mov_fisica_item', selected);
                }}
              />
            </Col>
          </Row>
          <br />

          <Tabs
            id="controlled-entrada-e-saida"
            activeKey={tab}
            onSelect={(ev) => {
              const tabSelected = ev as TabExtratoProps;
              setTab(tabSelected);
            }}
          >
            <Tab
              style={{ marginTop: '0.625rem' }}
              eventKey="Imposto"
              title="Impostos"
            >
              <TabImposto />
            </Tab>
            <Tab
              style={{ marginTop: '0.625rem' }}
              eventKey="Retencao"
              title="Retenção"
            >
              <TabRetencao />
            </Tab>
            <Tab
              style={{ marginTop: '0.625rem' }}
              eventKey="SpedPisConfins"
              title="SPED PIS/CONFINS"
            >
              <TabSpedPisConfins />
            </Tab>
          </Tabs>
        </FormDefault>
      )}
    </Container>
  );
};
