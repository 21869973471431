import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { CloseButton } from '../../style';
import { useIdenficacaoContabil } from '~/pages/IdentificacaoContabil/IdenficacaoContabilContext';
import { InputSelect } from '~/components/NovosInputs';
import { SelectProps } from '../../types';
import { Button } from 'react-bootstrap';

import Modal, {
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalTitle,
} from '@atlaskit/modal-dialog';
import { AiOutlineClose } from 'react-icons/ai';
import { SelectType } from '~/components/NovosInputs/InputSelect/protocols';

export const ModalNovoRetencao: React.FC = () => {
  const {
    modalOpenRetencao,
    handleCloseModalRetencao,
    optionsForneBeneficiario,
    optionsRetencao,

    registerRetencao,
    controlRetencao,
    formStateRetencao,
    setValueRetencao,
    getRetencao,
    handleAddNovoRetencao,
  } = useIdenficacaoContabil();

  return (
    <>
      {modalOpenRetencao && (
        <Modal
          width="x-large"
          height="350px"
          onClose={handleCloseModalRetencao}
        >
          <ModalHeader
            style={{
              paddingBottom: '0.5rem',
            }}
          >
            <ModalTitle>Adicionar Retenção</ModalTitle>
            <CloseButton onClick={handleCloseModalRetencao}>
              <AiOutlineClose size={24} />
            </CloseButton>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col md={12} sm={12}>
                <InputSelect
                  label="Fornecedor Beneficiário"
                  placeholder="Selecione o Fornecedor Beneficiário"
                  name="fornecedor_beneficiario"
                  register={registerRetencao}
                  options={optionsForneBeneficiario as SelectType[]}
                  isError={!!formStateRetencao.errors.fornecedor_beneficiario}
                  control={controlRetencao}
                  changeSelected={(selected: SelectProps) => {
                    setValueRetencao('fornecedor_beneficiario', selected);
                    getRetencao();
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col md={12} sm={12}>
                <InputSelect
                  label="Retenção"
                  name="retencao"
                  placeholder="Selecione a Retenção"
                  register={registerRetencao}
                  options={optionsRetencao as SelectType[]}
                  isError={!!formStateRetencao.errors.retencao}
                  control={controlRetencao}
                  changeSelected={(selected: SelectProps) => {
                    setValueRetencao('retencao', selected);
                  }}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '10px',
            }}
          >
            <Button
              style={{
                background: '#28a745',
                color: '#fff',
                border: 'none',
              }}
              onClick={handleAddNovoRetencao}
            >
              Adicionar
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};
