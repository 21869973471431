import React from 'react';

import { IdenficacaoContabilContextProvider } from './IdenficacaoContabilContext';
import { IdenficacaoContabilContent } from './IdenficacaoContabilContent';

export const IdentificacaoContabil: React.FC = () => {
  return (
    <IdenficacaoContabilContextProvider>
      <IdenficacaoContabilContent />
    </IdenficacaoContabilContextProvider>
  );
};
