export interface SelectProps {
  label: string | number | undefined;
  value: number | undefined | string;
}

export type TabExtratoProps = 'Imposto | Retencao | SpedPisConfins';

export interface RowImpostosProps {
  cod_seq_id_ctb_imposto?: number;
  cod_id_ctb?: number;
  campo: SelectProps;
  conta_credito: string;
  conta_debito: string;
  historico_padrao: SelectProps;
  id: string;
  uuid: string;
}
export interface RowRetencaoProps {
  fornecedor_beneficiario: SelectProps;
  retencao: SelectProps;
  id: string;
  uuid: string;
  cod_seq_id_forn_ret?: number;
  cod_id_ctb?: number;
}

export interface FormDataProps {
  cod_conta_cred: string | number;
  cod_conta_deb: number | undefined;
  cod_tab_sped_4_3_6: number | null;
  cod_tab_sped_4_3_7: number | undefined;
  des_id_ctb: string;
  flg_ignora_icms_nf_cli: boolean;
  flg_ignora_pis_cofins: boolean;
  impostos: any;
  retencao: any;
  tipo_mov_fisica_item: number;
}

export const dadosImposto = [
  { label: 'ICMS a Recuperar', value: 'VAL_ICMS_RECUP' },
  { label: 'ICMS a Pagar', value: 'VAL_ICMS_PAGAR' },
  { label: 'ICMS Antecipado (GUIA-ST)', value: 'VAL_ICMS_ANT' },
  { label: 'ICMS Antecipado (GUIA-ST DIF.)', value: 'VAL_ICMS_ANT_DIF' },
  { label: 'PIS a Recuperar', value: 'VAL_PIS_RECUP' },
  { label: 'PIS a Pagar', value: 'VAL_PIS_PAGAR' },
  { label: 'COFINS a Recuperar', value: 'VAL_COFINS_RECUP' },
  { label: 'COFINS a Pagar', value: 'VAL_COFINS_PAGAR' },
  { label: 'PIS a Recup. Imobilizado', value: 'VAL_PIS_RECUP_IMOB' },
  { label: 'COFINS a Recup. Imobilizado', value: 'VAL_COFINS_RECUP_IMOB' },
  { label: 'Crédito Outorgado', value: 'VAL_CREDITO_OUTORG' },
  { label: 'ICMS ST', value: 'VAL_ICMS_ST' },
];

export const masterDetailDefault = [
  {
    obj_name: 'impostos',
    pk_fields: ['cod_seq_id_ctb_imposto'],
    itens: {
      insert: [],
      update: [],
      delete: [],
    },
  },
  {
    obj_name: 'retencao',
    pk_fields: ['cod_seq_forn_ret'],
    itens: {
      insert: [],
      update: [],
      delete: [],
    },
  },
];
