/* eslint-disable no-lonely-if */
import React, {
  createContext,
  ReactNode,
  useContext,
  useState,
  useEffect,
} from 'react';

import {
  Control,
  FieldErrorsImpl,
  FieldValues,
  FormState,
  useForm,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormReset,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';
import { identificacaoContabilApi } from './services';
import { nanoid } from 'nanoid';
import { impostosSchema, schema, retencaoSchema } from './validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { insertOrUpdate, MasterDetailProps } from '~/utils/masterDetail';
import { toast } from 'react-toastify';
import {
  RowImpostosProps,
  SelectProps,
  RowRetencaoProps,
  masterDetailDefault,
} from './types';
import api from '~/services/api';

interface IdenficacaoContabilContextData {
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  control: Control<FieldValues, any>;
  register: UseFormRegister<FieldValues>;
  reset: UseFormReset<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  errors: FieldErrorsImpl<{
    [x: string]: any;
  }>;
  controlImpostos: Control<FieldValues, any>;
  registerImpostos: UseFormRegister<FieldValues>;
  setValueImpostos: UseFormSetValue<FieldValues>;
  formStateImpostos: FormState<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;

  registerRetencao: UseFormRegister<FieldValues>;
  controlRetencao: Control<FieldValues, any>;
  formStateRetencao: FormState<FieldValues>;
  setValueRetencao: UseFormSetValue<FieldValues>;
  getValuesImpostos: UseFormGetValues<FieldValues>;

  showSearch: boolean;

  setShowSearch: React.Dispatch<React.SetStateAction<boolean>>;
  isUpdate: boolean;
  setIsUpdate: React.Dispatch<React.SetStateAction<boolean>>;

  setTab: React.Dispatch<React.SetStateAction<string>>;
  tab: string;

  modalOpenImposto: boolean;
  setModalOpenImposto: React.Dispatch<React.SetStateAction<boolean>>;

  handleCloseModalImposto: () => void;
  handleOpenModalImposto: () => void;
  handleAddNovoImposto: () => void;
  optionsHistoricoPadrao: SelectProps[];
  rowsImpostos: RowImpostosProps[];

  handleResetImpostos: () => void;

  contaCreditoImposto: string;
  setContaCreditoImposto: React.Dispatch<React.SetStateAction<string>>;
  contaDebitoImposto: string;
  setContaDebitoImposto: React.Dispatch<React.SetStateAction<string>>;

  masterDetail: MasterDetailProps[];
  setMasterDetail: (masterDetail: MasterDetailProps[]) => void;
  setRowsImpostos: React.Dispatch<React.SetStateAction<RowImpostosProps[]>>;

  flgIgnoraIcmsNFClienteWatch: boolean;
  flgIgnoraPisCofinsWatch: boolean;

  modalOpenRetencao: boolean;
  setModalOpenRetencao: React.Dispatch<React.SetStateAction<boolean>>;

  handleOpenModalRetencao: () => void;
  handleCloseModalRetencao: () => void;

  optionsForneBeneficiario: SelectProps[];
  setOptionsForneBeneficiario: React.Dispatch<
    React.SetStateAction<SelectProps[]>
  >;
  optionsRetencao: any;

  getRetencao: () => void;

  handleAddNovoRetencao: () => void;

  rowsRetencao: RowRetencaoProps[];
  setRowsRetencao: React.Dispatch<React.SetStateAction<RowRetencaoProps[]>>;

  optionBcCred: any;
  setOptionBcCred: React.Dispatch<React.SetStateAction<any>>;
  optionTipoCred: any;
  setOptionTipoCred: React.Dispatch<React.SetStateAction<any>>;
  fetchSpedCodigosTipoCredito: (tabela: string) => void;

  fetchSpedCodigosBcCredito: (tabela: string) => void;
}

interface IdenficacaoContabilProps {
  children: ReactNode;
}

export const IdenficacaoContabilContext = createContext(
  {} as IdenficacaoContabilContextData,
);

export function IdenficacaoContabilContextProvider({
  children,
}: IdenficacaoContabilProps): JSX.Element {
  const [showSearch, setShowSearch] = useState<boolean>(true);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [tab, setTab] = useState<string>('Imposto');
  const [modalOpenImposto, setModalOpenImposto] = useState<boolean>(false);
  const [modalOpenRetencao, setModalOpenRetencao] = useState<boolean>(false);
  const [optionsHistoricoPadrao, setOptionsHistoricoPadrao] = useState<
    SelectProps[]
  >([]);
  const [optionsForneBeneficiario, setOptionsForneBeneficiario] = useState<
    SelectProps[]
  >([]);
  const [optionsRetencao, setOptionsRetencao] = useState<SelectProps[]>([]);
  const [rowsImpostos, setRowsImpostos] = useState<RowImpostosProps[]>([]);
  const [rowsRetencao, setRowsRetencao] = useState<RowRetencaoProps[]>([]);

  const [contaCreditoImposto, setContaCreditoImposto] = useState<string>('');
  const [contaDebitoImposto, setContaDebitoImposto] = useState<string>('');

  const [optionBcCred, setOptionBcCred] = useState([]);
  const [optionTipoCred, setOptionTipoCred] = useState([]);

  const {
    handleSubmit,
    register,
    control,
    reset,
    setValue,
    setError,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  const flgIgnoraIcmsNFClienteWatch = watch('flg_ignora_icms_nf_cliente');
  const flgIgnoraPisCofinsWatch = watch('flg_ignora_pis_cofins');

  const {
    handleSubmit: handleSubmitImpostos,
    register: registerImpostos,
    control: controlImpostos,
    reset: resetImpostos,
    getValues: getValuesImpostos,
    setValue: setValueImpostos,
    formState: formStateImpostos,
  } = useForm({
    resolver: yupResolver(impostosSchema),
    reValidateMode: 'onBlur',
  });
  const {
    handleSubmit: handleSubmitRetencao,
    register: registerRetencao,
    control: controlRetencao,
    reset: resetRetencao,
    getValues: getValuesRetencao,
    setValue: setValueRetencao,
    formState: formStateRetencao,
  } = useForm({
    resolver: yupResolver(retencaoSchema),
    reValidateMode: 'onBlur',
  });

  const formataOpcoes = (opcoes: any) => {
    const opcaoFormatada = opcoes.map((itemArr: any) => {
      return {
        value: itemArr.cod_tab_sped.toString().padStart('3', '0'),
        descricao: itemArr.des_tab_sped,
        label: itemArr.descricao,
      };
    });
    return opcaoFormatada;
  };

  useEffect(() => {
    fetchSpedCodigosBcCredito('4.3.7');
    fetchSpedCodigosTipoCredito('4.3.6');
  }, []);

  const fetchSpedCodigosBcCredito = async (tabela: string) => {
    try {
      const { data } = await api.post(`/produto/consulta-sped`, {
        cod_tabela: tabela && tabela.toString(),
      });
      const opcoesFormatada = formataOpcoes(data.data);
      setOptionBcCred(opcoesFormatada);
      setValue('optionBcCred', opcoesFormatada);
    } catch (e) {
      setOptionBcCred([]);
    }
  };
  const fetchSpedCodigosTipoCredito = async (tabela: string) => {
    try {
      const { data } = await api.post(`/produto/consulta-sped`, {
        cod_tabela: tabela && tabela.toString(),
      });
      const opcoesFormatada = formataOpcoes(data.data);
      setOptionTipoCred(opcoesFormatada);
      setValue('optionTipoCred', opcoesFormatada);
    } catch (e) {
      setOptionTipoCred([]);
    }
  };

  const [masterDetail, setMasterDetail] =
    useState<MasterDetailProps[]>(masterDetailDefault);

  const handleCloseModalImposto = () => {
    setModalOpenImposto(false);
  };
  const handleCloseModalRetencao = () => {
    setModalOpenRetencao(false);
  };

  const handleOpenModalImposto = () => {
    getHistorico();
    handleResetImpostos();
    setModalOpenImposto(true);
  };
  const handleOpenModalRetencao = () => {
    setModalOpenRetencao(true);
    getFornBeneficiario();
    handleResetRetencao();
  };

  const handleAddNovoImposto = handleSubmitImpostos(async (data) => {
    if (data.conta_credito === data.conta_debito) {
      return toast.warning(
        'Conta credito e conta debito devem ser diferentes.',
      );
    }

    const impostoExistente = rowsImpostos.find(
      (imposto: RowImpostosProps) => imposto.campo.value === data.campo.value,
    );
    if (impostoExistente) {
      return toast.warning('Campo já incluído.');
    }

    data.conta_credito = Number(data.conta_credito);
    data.conta_debito = Number(data.conta_debito);

    const novoImposto = {
      uuid: nanoid(),
      id: nanoid(),
      ...data,
    };

    const impostosAtualizados: any[] = await insertOrUpdate(
      'impostos',
      novoImposto,
      masterDetail,
      setMasterDetail,
    );

    setRowsImpostos(impostosAtualizados);

    resetImpostos();
    handleCloseModalImposto();
  });

  const handleResetImpostos = () => {
    resetImpostos();
    setContaCreditoImposto('');
    setContaDebitoImposto('');
    setValueImpostos('campo', { value: '', label: '' });
    setValueImpostos('conta_debito', '');
    setValueImpostos('conta_credito', '');
  };

  const handleAddNovoRetencao = handleSubmitRetencao(async (data) => {
    const novaRetencao = {
      uuid: nanoid(),
      id: nanoid(),
      ...data,
    };

    const retencaoExistente = rowsRetencao.find(
      (retencao: RowRetencaoProps) =>
        retencao.fornecedor_beneficiario.value ===
        data.fornecedor_beneficiario.value,
    );
    if (retencaoExistente) {
      return toast.warning('Forncedor já incluído.');
    }

    const retencoesAtualizadas: any[] = await insertOrUpdate(
      'retencao',
      novaRetencao,
      masterDetail,
      setMasterDetail,
    );

    setRowsRetencao(retencoesAtualizadas);

    handleResetRetencao();
    handleCloseModalRetencao();
  });

  const handleResetRetencao = () => {
    resetRetencao();
    setOptionsRetencao([]);
  };

  useEffect(() => {
    if (!!errors.cod_bc_cred && !!errors.cod_tipo_cred) {
      setTab('SpedPisConfins');
      toast.warning(
        'Código BC Crédito e Código Tipo Crédito devem ser informados.',
      );
    }
  }, [errors]);

  const getHistorico = async () => {
    try {
      const { data } = await identificacaoContabilApi.getHistoricoPadrao();
      const options = data.map((item: any) => ({
        value: item.cod_historico,
        label: item.des_historico_padrao,
      }));

      setOptionsHistoricoPadrao(options);
    } catch (error) {
      setOptionsHistoricoPadrao([]);
    }
  };
  const getFornBeneficiario = async () => {
    try {
      const { data } =
        await identificacaoContabilApi.getFornecedorBeneficiario();
      const options = data.map((item: any) => ({
        value: item.cod_fornecedor,
        label: item.des_fornecedor,
      }));
      setOptionsForneBeneficiario(options);
    } catch (error) {
      setOptionsForneBeneficiario([]);
    }
  };
  const getRetencao = async () => {
    const { fornecedor_beneficiario } = getValuesRetencao();
    try {
      const { data, success } =
        await identificacaoContabilApi.getRetencaoFornecedor(
          fornecedor_beneficiario.value,
        );
      if (success)
        if (data.length > 0) {
          const options = data.map((item: any) => ({
            value: item.cod_retencao,
            label: item.descricao,
          }));

          setOptionsRetencao(options);
        }
    } catch (error) {
      setOptionsRetencao([]);
    }
  };

  useEffect(() => {
    getHistorico();
  }, []);

  return (
    <IdenficacaoContabilContext.Provider
      value={{
        showSearch,
        setShowSearch,
        isUpdate,
        setIsUpdate,

        setTab,
        tab,
        handleSubmit,
        control,
        reset,
        register,
        setValue,
        errors,
        setError,
        getValues,
        setModalOpenImposto,
        modalOpenImposto,
        handleOpenModalImposto,
        handleCloseModalImposto,
        handleAddNovoImposto,
        optionsHistoricoPadrao,
        rowsImpostos,

        controlImpostos,
        registerImpostos,
        setValueImpostos,
        formStateImpostos,
        handleResetImpostos,

        contaCreditoImposto,
        setContaCreditoImposto,
        contaDebitoImposto,
        setContaDebitoImposto,

        masterDetail,
        setMasterDetail,

        setRowsImpostos,
        flgIgnoraIcmsNFClienteWatch,
        flgIgnoraPisCofinsWatch,

        modalOpenRetencao,
        setModalOpenRetencao,
        handleOpenModalRetencao,
        handleCloseModalRetencao,
        optionsForneBeneficiario,
        setOptionsForneBeneficiario,
        optionsRetencao,

        registerRetencao,
        controlRetencao,
        formStateRetencao,
        setValueRetencao,
        getRetencao,
        handleAddNovoRetencao,
        rowsRetencao,
        setRowsRetencao,

        optionBcCred,
        setOptionBcCred,
        optionTipoCred,
        setOptionTipoCred,
        fetchSpedCodigosTipoCredito,
        fetchSpedCodigosBcCredito,
        getValuesImpostos,
      }}
    >
      {children}
    </IdenficacaoContabilContext.Provider>
  );
}

export const useIdenficacaoContabil = (): IdenficacaoContabilContextData => {
  return useContext(IdenficacaoContabilContext);
};
