import api from '~/services/api';
import { FormDataProps } from '../types';

export const identificacaoContabilApi = {
  getHistoricoPadrao: async (): Promise<any> => {
    const request = await api.get(`/historico-padrao`);
    const { success, message, data } = request.data;

    return {
      success,
      data,
      message,
    };
  },
  getFornecedorBeneficiario: async (): Promise<any> => {
    const request = await api.get(`/fornecedor-beneficiario`);
    const { success, message, data } = request.data;

    return {
      success,
      data,
      message,
    };
  },
  getRetencaoFornecedor: async (cod_fornecedor: number): Promise<any> => {
    const request = await api.get(`/retencao-fornecedor/${cod_fornecedor}`);
    const { success, message, data } = request.data;

    return {
      success,
      data,
      message,
    };
  },

  getImpostos: async (cod_id_ctb: number): Promise<any> => {
    const request = await api.get(`/impostos/${cod_id_ctb}`);
    const { success, message, data } = request.data;

    return {
      success,
      data,
      message,
    };
  },
  getRetencoes: async (cod_id_ctb: number): Promise<any> => {
    const request = await api.get(`/retencoes/${cod_id_ctb}`);
    const { success, message, data } = request.data;

    return {
      success,
      data,
      message,
    };
  },
  getContaContabil: async (cod_conta: number): Promise<any> => {
    const request = await api.get(`/contaContabilId/${cod_conta}`);
    const { success, message, data } = request.data;

    return {
      success,
      data,
      message,
    };
  },
  postIdentificacaoContabil: async (formData: FormDataProps): Promise<any> => {
    const request = await api.post(`/identificacao-contabil`, {
      ...formData,
    });
    const { success, message } = request.data;

    return {
      success,
      message,
    };
  },
  putIdentificacaoContabil: async (
    formData: FormDataProps,
    cod_id_ctb: number,
  ): Promise<any> => {
    const request = await api.put(`/identificacao-contabil/${cod_id_ctb}`, {
      ...formData,
    });
    const { success, message } = request.data;

    return {
      success,
      message,
    };
  },
  deleteIdentificacaoContabil: async (cod_id_ctb: number): Promise<any> => {
    const request = await api.delete(`/identificacao-contabil/${cod_id_ctb}`);
    const { success, message } = request.data;

    return {
      success,
      message,
    };
  },
};
