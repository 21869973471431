import styled from 'styled-components';
import { Button } from 'react-bootstrap';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  /* display: flex;
  justify-content: center;
  align-items: center; */

  .myDataGrid .hideRightSeparator .MuiDataGrid-columnSeparator {
    display: none;
  }
`;

export const ButtonItens = styled.button`
  display: flex;
  background: none;
  border: none;
  gap: 5px;
  align-items: center;
  height: 40px;
  padding: 10px;
  margin-top: 10px;
  svg {
    font-size: 16pt;
  }
  span {
    color: rgb(107, 119, 140);
  }
  &:hover {
    border: #4c9aff;
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

export const CloseButton = styled(Button)`
  background-color: transparent;
  color: #000;
  border: none;

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    color: #504e4e;
    box-shadow: none !important;
  }
`;

export const ButtonRow = styled.button`
  border: none;
  background-color: transparent;
  color: #3d3d3d;
  &:nth-child(2) {
    margin-left: 10px;
  }
  &:hover {
    background-color: #f4f5f7 !important;
    color: #000;
    transition: 0.4s;
  }
`;
