import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { CloseButton } from '../../style';
import { useIdenficacaoContabil } from '~/pages/IdentificacaoContabil/IdenficacaoContabilContext';
import { InputSelect } from '~/components/NovosInputs';
import ContaContabil from '~/components/ContaContabil';
import { dadosImposto, SelectProps } from '../../types';
import { Button } from 'react-bootstrap';

import Modal, {
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalTitle,
} from '@atlaskit/modal-dialog';
import { AiOutlineClose } from 'react-icons/ai';
import { SelectType } from '~/components/NovosInputs/InputSelect/protocols';

export const ModalNovoImposto: React.FC = () => {
  const {
    modalOpenImposto,
    handleCloseModalImposto,
    handleAddNovoImposto,
    optionsHistoricoPadrao,
    controlImpostos,
    registerImpostos,
    setValueImpostos,
    formStateImpostos,
    contaCreditoImposto,
    setContaCreditoImposto,
    contaDebitoImposto,
    setContaDebitoImposto,
  } = useIdenficacaoContabil();

  useEffect(() => {
    setTimeout(() => {
      const campo = document.querySelector(
        '[name="campo"]',
      ) as HTMLInputElement;
      if (campo) {
        campo.focus();
      }
    }, 500);
  }, [modalOpenImposto]);

  return (
    <>
      {modalOpenImposto && (
        <Modal width="x-large" onClose={handleCloseModalImposto}>
          <ModalHeader
            style={{
              paddingBottom: '0.5rem',
            }}
          >
            <ModalTitle>Adicionar Conta Contábil</ModalTitle>
            <CloseButton onClick={handleCloseModalImposto}>
              <AiOutlineClose size={24} />
            </CloseButton>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col md={12} sm={12}>
                <InputSelect
                  label="Campo"
                  placeholder="Selecione o campo"
                  name="campo"
                  register={registerImpostos}
                  options={dadosImposto}
                  isError={!!formStateImpostos.errors.campo}
                  control={controlImpostos}
                  changeSelected={(selected: SelectProps) => {
                    setValueImpostos('campo', selected);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={8} sm={12}>
                <ContaContabil
                  label="Conta Débito"
                  value={contaDebitoImposto}
                  maxLength={3}
                  max={1000}
                  min={0}
                  lengthBuscaConta={0}
                  isRequired
                  setInvalid={!!formStateImpostos.errors.conta_debito}
                  iniInicializado={!!formStateImpostos.errors.conta_debito}
                  isDisabled={false}
                  onChange={(newValue: any, isInvalid: any, cod_conta: any) => {
                    setValueImpostos('conta_debito_reduzida', newValue);
                    setValueImpostos('conta_debito', Number(cod_conta));
                    setContaDebitoImposto(newValue);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={8} sm={12}>
                <ContaContabil
                  label="Conta Crédito"
                  value={contaCreditoImposto}
                  maxLength={3}
                  max={1000}
                  min={0}
                  lengthBuscaConta={0}
                  isRequired
                  setInvalid={!!formStateImpostos.errors.conta_credito}
                  iniInicializado={!!formStateImpostos.errors.conta_credito}
                  isDisabled={false}
                  onChange={(newValue: any, isInvalid: any, cod_conta: any) => {
                    setValueImpostos('conta_credito_reduzida', newValue);
                    setContaCreditoImposto(newValue);
                    setValueImpostos('conta_credito', Number(cod_conta));
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} sm={12}>
                <InputSelect
                  label="Historico Padrão/Lançamento"
                  name="historico_padrao"
                  placeholder="Selecione o Historico Padrão/Lançamento"
                  register={registerImpostos}
                  options={optionsHistoricoPadrao as SelectType[]}
                  isError={!!formStateImpostos.errors.historico_padrao}
                  control={controlImpostos}
                  changeSelected={(selected: SelectProps) => {
                    setValueImpostos('historico_padrao', selected);
                  }}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '10px',
            }}
          >
            <Button
              style={{
                background: '#28a745',
                color: '#fff',
                border: 'none',
              }}
              onClick={handleAddNovoImposto}
            >
              Adicionar
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};
